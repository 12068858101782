import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { ExportButton } from '@/Components/Advanced/exportButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { StaffEvaluationSearch } from '@/Components/Pages/StaffEvaluation/_StaffEvaluation/search'
import { StaffEvaluationList } from '@/Components/Pages/StaffEvaluation/_StaffEvaluation/list'
import ReactLoading from 'react-loading'
import ReactPaginate from 'react-paginate'

// apis
import { fetchStaffEvaluations } from '@/Apis/scopeProjectRecorder'

// libs
import { rankingTotalPointByRate } from '@/libs/calculator-evaluation-rate'
import { csvDownload } from '@/libs/csv-download'

// components
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

export const StaffEvaluation: React.FC<{}> = () => {
  const date = new Date()
  const month = date.getMonth() + 1;
  if (month <= 3) {
    date.setFullYear(date.getFullYear() - 1);
  }
  let half = '';
  if (4 <= month && month <= 9) {
    half = 'FirstHalf';
  } else {
    half = 'SecondHalf';
  }
  const period = half;

  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationDate } = useContext<any>(MasterContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [searchHeadquarterId, setSearchHeadquarterId] = useState<string>('')
  const [searchDepartmentId, setSearchDepartmentId] = useState<string>('')
  const [searchRate, setSearchRate] = useState<string>('')
  const [staffEvaluations, setStaffEvaluations] = useState<any>([])
  const [staffEvaluationsForCSV, setStaffEvaluationsForCSV] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [searchEvaluationYear, setSearchEvaluationYear] = useState<string>(
    query.searchEvaluationYear ? query.searchEvaluationYear : date.getFullYear(),
  )
  const [searchEvaluationDate, setSearchEvaluationDate] = useState<string>(
    query.searchEvaluationDate ? query.searchEvaluationDate : `${date.getFullYear()}${period}`,
  )
  const _masterEvaluationDate = masterEvaluationDate.find((_item, index) => _item.id === searchEvaluationDate)
  const [evaluationBeginDate, setEvaluationBeginDate] = useState<string>(_masterEvaluationDate.begin_date)
  const [evaluationEndDate, setEvaluationEndDate] = useState<string>(_masterEvaluationDate.end_date)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sortKey, setSortKey] = useState<string>('')
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC')
  // ページング
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 100;

  const _sortSetting = (key: string) => {
    setSortKey(key)
    // 選択したキーが初回の場合、昇順にする
    if (key !== sortKey) {
      setSortType('ASC')
    }
    if (key === sortKey) {
      setSortType(sortType === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  const _sort = () => {
    const _staffEvaluations = [...staffEvaluations]
    _staffEvaluations.sort((a, b) => {
      if (isNaN(a[sortKey])) {
        if (sortType === 'ASC') {
          return a[sortKey] > b[sortKey] ? 1 : -1
        } else {
          return a[sortKey] < b[sortKey] ? 1 : -1
        }
      } else {
        if (sortType === 'ASC') {
          return a[sortKey] - b[sortKey]
        } else {
          return b[sortKey] - a[sortKey]
        }
      }
    })
    setStaffEvaluations(_staffEvaluations)
  }

  const handleChange = e => {
    if (e.target.name === 'headquarterId') {
      setSearchHeadquarterId(e.target.value)
    }

    if (e.target.name === 'departmentId') {
      setSearchDepartmentId(e.target.value)
    }

    if (e.target.name === 'rate') {
      setSearchRate(e.target.value)
    }

    if (e.target.name === 'evaluationYear') {
      setSearchEvaluationYear(e.target.value)
    }

    if (e.target.name === 'evaluationDate') {
      setSearchEvaluationDate(e.target.value)
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === e.target.value)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }

  const _onSearch = () => {
    setIsLoading(true)

    // 検索したらソートはリセットされる
    setSortKey('')
    setSortType('ASC')
    setCurrentPage(0)

    // スタッフ評価再取得
    _fetchStaffEvaluations()
    history.replace({
      pathname: `/staff-evaluation/`,
      search: `?searchEvaluationYear=${searchEvaluationYear}&searchEvaluationDate=${searchEvaluationDate}`,
    })
  }

  const _onReset = () => {
    // 本部
    setSearchHeadquarterId('')
    if (auth) {
      if (auth.rate === 6) {
        setSearchHeadquarterId(auth.headquarter_id)
      }
    }
    // 部署
    setSearchDepartmentId('')
    // 等級
    setSearchRate('')
    // 評価年月日
    // 評価年月日(年度)
    setSearchEvaluationYear(`${date.getFullYear()}`)
    // 評価年月日(期間)
    setSearchEvaluationDate(`${date.getFullYear()}${period}`)
    // 期間を選択した際の開始日時
    setEvaluationBeginDate('')
    // 期間を選択した際の終了日時
    setEvaluationEndDate('')
    // データのリセット
    setStaffEvaluations([])
    setListTotalCount(0)
    setCurrentPage(0)
  }

  const _fetchStaffEvaluations = async () => {
    // 評価計算用のデータを取得
    await fetchStaffEvaluations(
      '',
      '',
      '',
      searchEvaluationYear,
      searchEvaluationDate,
      evaluationBeginDate,
      evaluationEndDate,
      // 絞り込み検索をしたスタッフ一覧
    ).then(async _staffEvaluations => {
      // 評価データの計算
      // @ts-ignore
      const _staffEvaluationsRankingTotalPoint = rankingTotalPointByRate(_staffEvaluations.data.staffEvaluations)
      await fetchStaffEvaluations(
        searchHeadquarterId,
        searchDepartmentId,
        searchRate,
        searchEvaluationYear,
        searchEvaluationDate,
        evaluationBeginDate,
        evaluationEndDate,
      ).then(data => {
        // @ts-ignore
        const _staffEvaluations = data.data.staffEvaluations
        // @ts-ignore
        _staffEvaluations.forEach((staffEvaluation, index) => {
          // 絞り込み検索したデータに評価データをマッピング
          _staffEvaluations[index] = _staffEvaluationsRankingTotalPoint.find(item => item.user_id === staffEvaluation.user_id)
        })
        // @ts-ignore
        setStaffEvaluations(_staffEvaluations)
        // @ts-ignore
        setListTotalCount(data.data.total)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
    })
  }

  useEffect(() => {
    if (sortKey) {
      _sort()
    }
  }, [sortKey, sortType])

  const _csvDownload = () => {
    csvDownload(staffEvaluationsForCSV, 'staff-evaluations.csv')
  }

  useEffect(() => {
    const _searchEvaluationYear = searchEvaluationYear ? searchEvaluationYear : date.getFullYear()
    setSearchEvaluationDate(`${_searchEvaluationYear}${period}`)
    if (masterEvaluationDate) {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === `${_searchEvaluationYear}${period}`)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }, [searchEvaluationYear])

  useEffect(() => {
    if (staffEvaluations) {
      const csvHeader = [
        '本部',
        '部署',
        '等級',
        'スタッフ名',
        'プロジェクト数',
        'プロジェクト数(評価済み)',
        'プロジェクト数(未登録)',
        '個人タスク合計',
        '個人タスク平均',
        '貢献度合計',
        '貢献度平均',
        '合計点数',
        '合計平均',
        '倍率加算前平均',
        '倍率加算前平均×PJ数',
        '順位評価（同等級）',
        '順位評価（前後等級）',
        '偏差値評価（偏差値）',
        '偏差値評価（同等級）',
        '偏差値評価（偏差値）',
        '偏差値評価（前後等級）',
      ]
      const csvData = staffEvaluations.map((staffEvaluation: any) => {
        return [
          staffEvaluation.headquarter_name,
          staffEvaluation.department_name,
          staffEvaluation.rate,
          `${staffEvaluation.last_name} ${staffEvaluation.first_name}`,
          staffEvaluation.project_count,
          staffEvaluation.project_count_registered,
          staffEvaluation.project_count_nonRegistered,
          staffEvaluation.task_point_total_csv,
          staffEvaluation.task_point_total_avg_csv,
          staffEvaluation.contribution_point_total_csv,
          staffEvaluation.contribution_point_total_avg_csv,
          staffEvaluation.total_point_csv,
          staffEvaluation.total_point_avg_csv,
          staffEvaluation.averagebeforeMagnificationAddition,
          staffEvaluation.averagebeforeMagnificationAdditionPj,
          staffEvaluation.rankingEvaluationEquivalentGrade,
          staffEvaluation.rankingEvaluationBeforeAndAfterGrade,
          staffEvaluation.deviationValueEvaluationEquivalentGrade,
          staffEvaluation.deviationValueEvaluationEquivalentGradePoint,
          staffEvaluation.deviationValueEvaluationBeforeAndAfterGrade,
          staffEvaluation.deviationValueEvaluationBeforeAndAfterGradePoint,
        ]
      })
      setStaffEvaluationsForCSV([csvHeader, ...csvData])
    }
  }, [staffEvaluations])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 5等級以下はリダイレクト
      if (auth.rate <= 5) {
        history.push({
          pathname: `/`,
        })
      }
      if (auth.rate === 6) {
        setSearchHeadquarterId(auth.headquarter_id)
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected);
  };

  const displayedStaffEvaluations = staffEvaluations.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="staff-evaluation" />
        <div css={containerStyle}>
          <PageTitle title={'スタッフ評価一覧'} />
          <div css={mainTopContentsStyle}>
            <StaffEvaluationSearch
              searchHeadquarterId={searchHeadquarterId}
              searchDepartmentId={searchDepartmentId}
              searchRate={searchRate}
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
              handleChange={handleChange}
              onSearch={_onSearch}
              onReset={_onReset}
            />
            <div css={searchSideButtonWrapperStyle}>
              <ExportButton onClick={_csvDownload} />
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            {staffEvaluations.length !== 0 && (<ReactPaginate
              pageCount={Math.ceil(staffEvaluations.length / itemsPerPage)} // ページ数
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-center" // ul(pagination本体)
              pageClassName="page-item" // li
              pageLinkClassName="page-link rounded-full" // a
              activeClassName="page-item-active" // active.li
              activeLinkClassName="page-item-active" // active.li < a

              // 戻る・進む関連
              previousClassName="previous-next-item" // li
              nextClassName="previous-next-item" // li
              previousLabel={'<'} // a
              previousLinkClassName="previous-link"
              nextLabel={'>'} // a
              nextLinkClassName="next-link"

              // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする
              disabledClassName="disabled-button d-none"

              // 中間ページの省略表記関連
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />)}
            <StaffEvaluationList
              staffEvaluations={displayedStaffEvaluations}
              sort={_sortSetting}
              sortKey={sortKey}
              sortType={sortType}
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
            />
          </div>
          {isLoading && (
            <div css={loadingOverlayStyle}>
              <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  '.field': {
    marginBottom: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1000
})

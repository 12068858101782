import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { ExportButton } from '@/Components/Advanced/exportButton'
import { AddButton } from '@/Components/Advanced/addButton'
import { ListPageCount } from '@/Components/Advanced/listPageCount'
import { ProjectsSearch } from '@/Components/Pages/Project/_Project/search'
import { ProjectsList } from '@/Components/Pages/Project/_Project/list'
import ReactLoading from 'react-loading'
import ReactPaginate from 'react-paginate'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// apis
import { fetchProjects, fetchProjectsEvaluatorMembers } from '@/Apis/scopeProjectRecorder'

// libs
import { csvDownload } from '@/libs/csv-download'

// components
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

export const Projects: React.FC<{}> = () => {
  const date = new Date()
  const month = date.getMonth() + 1;
  if (month <= 3) {
    date.setFullYear(date.getFullYear() - 1);
  }
  let half = '';
  if (4 <= month && month <= 9) {
    half = 'FirstHalf';
  } else {
    half = 'SecondHalf';
  }
  const period = half;

  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationDate, masterProjectStatus } = useContext<any>(MasterContext)
  const history = useHistory()
  const query = require('query-string').parse(window.location.search)
  const [projects, setProjects] = useState<any>([])
  const [projectsForCSV, setProjectsForCSV] = useState<any>([])
  const [projectsEvaluatorMembers, setProjectsEvaluatorMembers] = useState<any>([])
  const [listTotalCount, setListTotalCount] = useState<number>(0)
  const [searchProjectName, setSearchProjectName] = useState<string>('')
  const [searchUserId, setSearchUserId] = useState<string>('')
  const [searchEvaluationId, setSearchEvaluationId] = useState<string>('')
  const [searchEvaluationStatus, setSearchEvaluationStatus] = useState<string>('all')
  const [searchProjectStatus, setSearchProjectStatus] = useState<string>('')
  const [searchEvaluationYear, setSearchEvaluationYear] = useState<string>(
    query.searchEvaluationYear ? query.searchEvaluationYear : date.getFullYear(),
  )
  const [searchEvaluationDate, setSearchEvaluationDate] = useState<string>(
    query.searchEvaluationDate ? query.searchEvaluationDate : `${date.getFullYear()}${period}`,
  )
  const _masterEvaluationDate = masterEvaluationDate.find((_item, index) => _item.id === searchEvaluationDate)
  const [evaluationBeginDate, setEvaluationBeginDate] = useState<string>(_masterEvaluationDate.begin_date)
  const [evaluationEndDate, setEvaluationEndDate] = useState<string>(_masterEvaluationDate.end_date)
  const [targetDate, setTargetDate] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [beginDate, setBeginDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [sortKey, setSortKey] = useState<string>('')
  const [sortType, setSortType] = useState<'ASC' | 'DESC'>('ASC')
  // ページング
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 100;

  const _sortSetting = (key: string) => {
    setSortKey(key)
    // 選択したキーが初回の場合、昇順にする
    if (key !== sortKey) {
      setSortType('ASC')
    }
    if (key === sortKey) {
      setSortType(sortType === 'ASC' ? 'DESC' : 'ASC')
    }
  }

  const _sort = () => {
    const _projects = [...projects]
    _projects.sort((a, b) => {
      if (isNaN(a[sortKey])) {
        if (sortType === 'ASC') {
          return a[sortKey] > b[sortKey] ? 1 : -1
        } else {
          return a[sortKey] < b[sortKey] ? 1 : -1
        }
      } else {
        if (sortType === 'ASC') {
          return a[sortKey] - b[sortKey]
        } else {
          return b[sortKey] - a[sortKey]
        }
      }
    })
    setProjects(_projects)
  }

  const _changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
    setSearchEvaluationDate('')
  }

  const handleEvaluationStatusChange = value => {
    setSearchEvaluationStatus(value)
  }

  const handleChange = e => {
    if (e.target.name === 'keyword') {
      setSearchProjectName(e.target.value)
    }

    if (e.target.name === 'userId') {
      setSearchUserId(e.target.value)
    }

    if (e.target.name === 'evaluationId') {
      setSearchEvaluationId(e.target.value)
    }

    if (e.target.name === 'evaluationStatus') {
      setSearchEvaluationStatus(e.target.value)
    }

    if (e.target.name === 'projectStatus') {
      setSearchProjectStatus(e.target.value)
    }

    if (e.target.name === 'evaluationYear') {
      setSearchEvaluationYear(e.target.value)
    }

    if (e.target.name === 'evaluationDate') {
      setSearchEvaluationDate(e.target.value)
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === e.target.value)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
      // プロジェクト期間検索をリセット
      setBeginDate('')
      setEndDate('')
    }

    if (e.target.name === 'targetDate') {
      setTargetDate(e.target.value)
      if (e.target.value === 'evaluation_date') {
        // 初期値として設定
        let _masterEvaluationDate;
        masterEvaluationDate.forEach(element => {
          if (element.year == date.getFullYear()) {
            if (4 <= month && month <= 9) {
              _masterEvaluationDate = element;
            } else if (10 <= month || month <= 3) {
              _masterEvaluationDate = element;
            }
          }
        });
        setEvaluationBeginDate(_masterEvaluationDate.begin_date)
        setEvaluationEndDate(_masterEvaluationDate.end_date)
        // プロジェクト期間検索をリセット
        setBeginDate('')
        setEndDate('')
      }
      if (e.target.value === 'project_date') {
        // 評価年月日のリセット
        setEvaluationBeginDate('')
        setEvaluationEndDate('')
      }
    }
  }

  const _onSearch = () => {
    setIsLoading(true)

    // 検索したらソートはリセットされる
    setSortKey('')
    setSortType('ASC')
    setCurrentPage(0)

    // プロジェクト再取得
    _fetchProjects()
  }

  const _onReset = () => {
    // 評価者
    setSearchUserId('')
    // 対象期間
    setTargetDate('')
    // 評価年月日
    // 評価年月日(年度)
    setSearchEvaluationYear(`${date.getFullYear()}`)
    // 評価年月日(期間)
    setSearchEvaluationDate(`${date.getFullYear()}${period}`)
    // 期間を選択した際の開始日時
    setEvaluationBeginDate('')
    // 期間を選択した際の終了日時
    setEvaluationEndDate('')
    // プロジェクト期間
    setBeginDate('')
    setEndDate('')
    // 評価登録
    setSearchEvaluationStatus('all')
    // 評価指標
    setSearchEvaluationId('')
    // ステータス
    setSearchProjectStatus('')
    // プロジェクト名
    setSearchProjectName('')
    // データのリセット
    setProjects([])
    setListTotalCount(0)
    setCurrentPage(0)
  }

  const goToProjectRegister = () => {
    history.push({
      pathname: `/project/register`,
    })
  }

  const _fetchProjects = async () => {
    await fetchProjects(
      searchProjectName,
      searchUserId,
      searchEvaluationId,
      searchEvaluationStatus,
      searchProjectStatus,
      searchEvaluationDate,
      beginDate,
      endDate,
      evaluationBeginDate,
      evaluationEndDate,
    ).then(data => {
      // @ts-ignore
      setProjects(data.data.projects)
      // @ts-ignore
      setListTotalCount(data.data.total)
      setIsLoading(false)
    })
  }

  const _fetchProjectsEvaluatorMembers = async () => {
    await fetchProjectsEvaluatorMembers().then(data => {
      // @ts-ignore
      setProjectsEvaluatorMembers(data.data.evaluator_members)
      // @ts-ignore
      setIsLoading(false)
    })
  }

  const _visibleRegisterMenu = () => {
    if (auth) {
      // マネージャー以上（4~8等級）では「プロジェクト登録」が表示される
      if (auth.rate >= 4) {
        return true
      }
    }

    // メンバー（1〜3等級）では「プロジェクト登録」が表示されない
    return false
  }

  useEffect(() => {
    if (sortKey) {
      _sort()
    }
  }, [sortKey, sortType])

  useEffect(() => {
    const _searchEvaluationYear = searchEvaluationYear ? searchEvaluationYear : date.getFullYear()
    setSearchEvaluationDate(`${_searchEvaluationYear}${period}`)
    if (masterEvaluationDate) {
      const _masterEvaluationDate = masterEvaluationDate.find(item => item.id === `${_searchEvaluationYear}${period}`)
      setEvaluationBeginDate(_masterEvaluationDate.begin_date)
      setEvaluationEndDate(_masterEvaluationDate.end_date)
    }
  }, [searchEvaluationYear])

  const _csvDownload = () => {
    csvDownload(projectsForCSV, 'projects.csv')
  }

  useEffect(() => {
    // データ取得後に加工な必要なデータ
    if (projects) {
      projects.forEach(project => {
        if (project.begin_date && project.end_date) {
          project.project_days = dayjs(project.end_date).diff(project.begin_date, 'day')
        }
        project.evaluation_status_csv = project.evaluation_status === 'registered' ? '完了' : '未登録'
        project.status_csv = project?.status ? masterProjectStatus.find(status => status.id === project?.status)?.name : '未登録'
      })
      const csvHeader = [
        'クライアント名称',
        'プロジェクト名',
        '開始日',
        '終了日',
        'プロジェクト日数',
        '評価登録',
        'ステータス',
        '評価者',
        '評価指標',
        '倍率',
        'スタッフ数',
      ]
      const csvData = projects.map((project: any) => {
        return [
          project.client_name,
          project.project_name,
          dayjs(project.begin_date).format('YYYY/MM/DD'),
          dayjs(project.end_date).format('YYYY/MM/DD'),
          project.project_days,
          project.evaluation_status_csv,
          project.status_csv,
          `${project.last_name} ${project.first_name}`,
          project.evaluation_index_name,
          project.evaluation_index_competition_edit !== '0' ? project.evaluation_index_competition_edit : project.evaluation_index_competition,
          project.project_members_count,
        ]
      })
      setProjectsForCSV([csvHeader, ...csvData])
    }
  }, [projects])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      setIsLoading(true)
      _fetchProjectsEvaluatorMembers()
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected);
  };

  const displayedProjects = projects.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト一覧'} />
          <div css={mainTopContentsStyle}>
            <ProjectsSearch
              projectsEvaluatorMembers={projectsEvaluatorMembers}
              searchProjectName={searchProjectName}
              searchEvaluationId={searchEvaluationId}
              searchUserId={searchUserId}
              searchEvaluationStatus={searchEvaluationStatus}
              searchProjectStatus={searchProjectStatus}
              searchEvaluationYear={searchEvaluationYear}
              searchEvaluationDate={searchEvaluationDate}
              searchBeginDate={beginDate}
              searchEndDate={endDate}
              targetDate={targetDate}
              changeRangeDate={_changeRangeDate}
              handleEvaluationStatusChange={handleEvaluationStatusChange}
              handleChange={handleChange}
              onSearch={_onSearch}
              onReset={_onReset}
            />
            <div css={searchSideButtonWrapperStyle}>
              <ExportButton onClick={_csvDownload} />
              {_visibleRegisterMenu() && <AddButton label={'プロジェクト登録'} onClick={goToProjectRegister} />}
            </div>
          </div>
          <div css={mainContentsStyle}>
            <ListPageCount pageCount={listTotalCount} />
            {projects.length !== 0 && (<ReactPaginate
              pageCount={Math.ceil(projects.length / itemsPerPage)} // ページ数
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-center" // ul(pagination本体)
              pageClassName="page-item" // li
              pageLinkClassName="page-link rounded-full" // a
              activeClassName="page-item-active" // active.li
              activeLinkClassName="page-item-active" // active.li < a

              // 戻る・進む関連
              previousClassName="previous-next-item" // li
              nextClassName="previous-next-item" // li
              previousLabel={'<'} // a
              previousLinkClassName="previous-link"
              nextLabel={'>'} // a
              nextLinkClassName="next-link"

              // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくする
              disabledClassName="disabled-button d-none"

              // 中間ページの省略表記関連
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />)}
            <ProjectsList projects={displayedProjects} sort={_sortSetting} sortKey={sortKey} sortType={sortType} />
          </div>
          {isLoading && (
            <div css={loadingOverlayStyle}>
              <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
  position: 'relative',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '34px',
  backgroundColor: '#EDF2F9',
})

const searchSideButtonWrapperStyle = css({
  '.field': {
    marginBottom: '10px',
  },
})

const mainTopContentsStyle = css({
  width: '100%',
  padding: '24px 34px 0px',
  display: 'flex',
  justifyContent: 'space-between',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})

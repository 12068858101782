import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { Button } from '@/Components/Advanced/button'
import { StaffRegisterListContent } from '@/Components/Pages/Project/_RegisterStaff/staffRegisterListContent'
import ReactLoading from 'react-loading'

// apis
import { projectRegister } from '@/Apis/scopeProjectRecorder'

// contexts
import { AuthContext } from '@/Contexts/auth'

export const ProjectRegisterStaff: React.FC<{}> = () => {
  const { auth } = useContext<any>(AuthContext)
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // @ts-ignore
  const [members, setMembers] = useState(location.state?.members)
  // プロジェクトに追加しているメンバーIDの除外リスト
  // @ts-ignore
  const [excludeUserIds, setExcludeUserIds] = useState<string[]>(location.state?.excludeUserIds)
  const [validationError, setValidationError] = useState<string[]>([])

  const goToProjectDetail = projectId => {
    history.push({
      pathname: `/project/detail`,
      search: `?projectId=${projectId}`,
    })
  }

  const handleChange = (e, index) => {
    const _members = [...members]

    if (e.target.name === 'headquarterId') {
      _members[index].headquarter_id = e.target.value
    }

    if (e.target.name === 'departmentId') {
      _members[index].department_id = e.target.value
    }

    if (e.target.name === 'positionId') {
      _members[index].position_id = e.target.value
    }

    if (e.target.name === 'userId') {
      _members[index].user_id = e.target.value
    }

    setMembers(_members)
  }

  const _changeRangeDate = (beginDate, endDate, index) => {
    const _members = [...members]
    _members[index].begin_date = beginDate
    _members[index].end_date = endDate
    setMembers(_members)
  }

  const _addMembers = () => {
    const _members = [...members]
    _members.push({
      user_id: '',
      headquarter_id: '',
      department_id: '',
      position_id: '',
      member_index: _members.length,
      begin_date: '',
      end_date: '',
      role: 'other',
    })
    setMembers(_members)
  }

  const _removeMembers = index => {
    const confirmResult = window.confirm('スタッフを削除しますか？')
    if (confirmResult) {
      const _members = [...members]
      const _reIndexMembers = _members.filter((_member, i) => i !== index)
      setMembers(_reIndexMembers)
    }
  }

  const _validate = () => {
    let error = false
    const _validationError: string[] = []

    members.forEach((member, index) => {
      if (member.user_id === '' || member.position_id === '' || member.begin_date === '' || member.end_date === '') {
        _validationError[index] = 'スタッフ情報を正しく設定してください'
        error = true
      }
      if (member.user_id !== '' && member.position_id !== '' && member.begin_date !== '' && member.end_date !== '') {
        _validationError[index] = ''
      }
    })

    if (error) {
      alert('入力内容に誤りがあります')
    }

    setValidationError(_validationError)
    return error
  }

  const _registerProject = async () => {
    if (_validate()) return
    setIsLoading(true)
    await projectRegister(
      // @ts-ignore
      location.state?.projectName,
      // @ts-ignore
      location.state?.clientName,
      // @ts-ignore
      location.state?.description,
      // @ts-ignore
      location.state?.beginDate,
      // @ts-ignore
      location.state?.endDate,
      // @ts-ignore
      location.state?.evaluationIndex,
      // @ts-ignore
      location.state?.inHouseCost,
      // @ts-ignore
      location.state?.competition,
      // @ts-ignore
      location.state?.competitionEdit,
      // @ts-ignore
      location.state?.evaluationDate,
      '',
      // @ts-ignore
      location.state?.projectStatus,
      members,
    ).then(e => {
      setIsLoading(false)
      // @ts-ignore
      goToProjectDetail(e.data.project_id)
    })
  }

  useEffect(() => {
    if (members) {
      // メンバーID除外リスト
      const _excludeUserIds:string[] = []
      members.forEach(member => {
        _excludeUserIds.push(member.user_id)
      }
      )
      setExcludeUserIds(_excludeUserIds)
    }
  }, [members])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 3等級以下はリダイレクト
      if (auth.rate <= 3) {
        history.push({
          pathname: `/`,
        })
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project-register" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト登録'} />
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton name={'基本情報へ戻る'} onClick={() => history.goBack()} />
            </div>
            <div css={stepWrapperStyle}>
              <img src={require('@/static/images/project-register-step2.svg')} />
            </div>
            <div css={mainListContainerStyle}>
              <ul css={mainListHeaderStyle}>
                <li css={list1Style}>本部</li>
                <li css={list2Style}>部署</li>
                <li css={list3Style}>スタッフ名</li>
                <li css={list4Style}>役割</li>
                <li css={list5Style}>アサイン期間</li>
              </ul>
              <ul css={mainListContentsStyle}>
                {members.map((member, index) => (
                  <StaffRegisterListContent
                    // @ts-ignore
                    minDate={location.state?.beginDate}
                    // @ts-ignore
                    maxDate={location.state?.endDate}
                    member={member}
                    index={index}
                    key={index}
                    handleChange={handleChange}
                    changeRangeDate={_changeRangeDate}
                    removeMembers={_removeMembers}
                    validationError={validationError[index]}
                    excludeUserIds={excludeUserIds}
                  />
                ))}
              </ul>
            </div>
            <div css={addStaffStyle} onClick={_addMembers}>
              <img css={addIconStyle} src={require('@/static/images/add_blue.svg')} />
              スタッフを追加する
            </div>
            <div css={saveButtonWrapperStyle}>
              <Button label={'登録完了'} onClick={_registerProject} />
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div css={loadingOverlayStyle}>
          <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
        </div>
      )}
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const stepWrapperStyle = css({
  marginBottom: '75px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const mainListContainerStyle = css({
  display: 'grid',
  width: 'calc(100vw - 220px - 68px)',
  overflowY: 'hidden',
  overflowX: 'scroll',
  maxHeight: '480px',
  border: '1px solid #DDDDDD',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
})

const mainListHeaderStyle = css({
  display: 'flex',
  width: '100%',
  height: '60px',
  backgroundColor: '#E6E6E6',
  padding: '20px 16px',
  // スクロールバーのサイズ = 15px　ヘッダーはスクロールバーを表示しない為、横幅調整
  marginRight: '15px',
  li: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
})

const mainListContentsStyle = css({
  width: '100%',
  height: '420px',
  overflowY: 'scroll',
  backgroundColor: '#FFFFFF',
  scrollbarWidth: 'none',
  cursor: 'pointer',
  '.list': {
    padding: '16px',
    borderBottom: '1px solid #DDDDDD',
  },
})

const list1Style = css({
  width: '120px',
})

const list2Style = css({
  width: '180px',
})

const list3Style = css({
  width: '200px',
})

const list4Style = css({
  width: '150px',
})

const list5Style = css({
  width: '350px',
  overflow: 'initial',
})

const saveButtonWrapperStyle = css({
  margin: '0 0 80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})

const addStaffStyle = css({
  margin: '30px 0 80px',
  width: '100%',
  color: '#004CAD',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '9px 0',
  backgroundColor: '#FFFFFF',
  border: '1px solid #004CAD',
  borderRadius: '6px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const addIconStyle = css({
  marginRight: '4px',
  width: '24px',
})

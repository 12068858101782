import React, { createContext, useState, useEffect } from 'react'

// apis
import {
  fetchMasterHeadquarters,
  fetchMasterDepartments,
  fetchMasterPositions,
  fetchMasterEvaluationIndexs,
  fetchMasterInHouseCosts,
  fetchMasterUsers,
} from '@/Apis/scopeProjectRecorder'

export const MasterContext = createContext({})

// 5段階評価
const _masterFinalEvaluationIndexs = [
  {
    id: '1',
    name: '1',
  },
  {
    id: '2',
    name: '2',
  },
  {
    id: '3',
    name: '3',
  },
  {
    id: '4',
    name: '4',
  },
  {
    id: '5',
    name: '5',
  },
]

const _masterEvaluationDate = [
  { id: '2022FirstHalf', year: '2022', name: '上期', begin_date: '2022-02-01', end_date: '2022-09-30' },
  { id: '2022SecondHalf', year: '2022', name: '下期', begin_date: '2022-10-01', end_date: '2023-03-31' },
  { id: '2023FirstHalf', year: '2023', name: '上期', begin_date: '2023-04-01', end_date: '2023-09-30' },
  { id: '2023SecondHalf', year: '2023', name: '下期', begin_date: '2023-10-01', end_date: '2024-03-31' },
  { id: '2024FirstHalf', year: '2024', name: '上期', begin_date: '2024-04-01', end_date: '2024-09-30' },
  { id: '2024SecondHalf', year: '2024', name: '下期', begin_date: '2024-10-01', end_date: '2025-03-31' },
]

const _masterEvaluationDate2022 = [
  { id: '2022FirstHalf', year: '2022', name: '上期', begin_date: '2022-02-01', end_date: '2022-09-30' },
  { id: '2022SecondHalf', year: '2022', name: '下期', begin_date: '2022-10-01', end_date: '2023-03-31' },
]

const _masterEvaluationDate2023 = [
  { id: '2023FirstHalf', year: '2023', name: '上期', begin_date: '2023-04-01', end_date: '2023-09-30' },
  { id: '2023SecondHalf', year: '2023', name: '下期', begin_date: '2023-10-01', end_date: '2024-03-31' },
]

const _masterEvaluationDate2024 = [
  { id: '2024FirstHalf', year: '2024', name: '上期', begin_date: '2024-04-01', end_date: '2024-09-30' },
  { id: '2024SecondHalf', year: '2024', name: '下期', begin_date: '2024-10-01', end_date: '2025-03-31' },
]

const _masterEvaluationYear = [{ id: '2022', name: '2022年度' }, { id: '2023', name: '2023年度' }, { id: '2024', name: '2024年度' }]

const _masterProjectStatus = [
  { id: 'unregistered', name: '未登録' },
  { id: 'orders-received', name: '受注' },
  { id: 'in-house-projects', name: '実施（社内案件時）' },
  { id: 'lost-order', name: '失注' },
  { id: 'cancel', name: '中止' },
  { id: 'delay', name: '延期' },
]

const _masterRates = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
]

const _masterEvaluationPoints = [
  { id: '0.1', name: '0.1' },
  { id: '0.2', name: '0.2' },
  { id: '0.3', name: '0.3' },
  { id: '0.4', name: '0.4' },
  { id: '0.5', name: '0.5' },
  { id: '0.6', name: '0.6' },
  { id: '0.7', name: '0.7' },
  { id: '0.8', name: '0.8' },
  { id: '0.9', name: '0.9' },
  { id: '1.0', name: '1.0' },
  { id: '1.1', name: '1.1' },
  { id: '1.2', name: '1.2' },
  { id: '1.3', name: '1.3' },
  { id: '1.4', name: '1.4' },
  { id: '1.5', name: '1.5' },
  { id: '1.6', name: '1.6' },
  { id: '1.7', name: '1.7' },
  { id: '1.8', name: '1.8' },
  { id: '1.9', name: '1.9' },
  { id: '2.0', name: '2.0' },
  { id: '2.1', name: '2.1' },
  { id: '2.2', name: '2.2' },
  { id: '2.3', name: '2.3' },
  { id: '2.4', name: '2.4' },
  { id: '2.5', name: '2.5' },
  { id: '2.6', name: '2.6' },
  { id: '2.7', name: '2.7' },
  { id: '2.8', name: '2.8' },
  { id: '2.9', name: '2.9' },
  { id: '3.0', name: '3.0' },
  { id: '3.1', name: '3.1' },
  { id: '3.2', name: '3.2' },
  { id: '3.3', name: '3.3' },
  { id: '3.4', name: '3.4' },
  { id: '3.5', name: '3.5' },
  { id: '3.6', name: '3.6' },
  { id: '3.7', name: '3.7' },
  { id: '3.8', name: '3.8' },
  { id: '3.9', name: '3.9' },
  { id: '4.0', name: '4.0' },
  { id: '4.1', name: '4.1' },
  { id: '4.2', name: '4.2' },
  { id: '4.3', name: '4.3' },
  { id: '4.4', name: '4.4' },
  { id: '4.5', name: '4.5' },
  { id: '4.6', name: '4.6' },
  { id: '4.7', name: '4.7' },
  { id: '4.8', name: '4.8' },
  { id: '4.9', name: '4.9' },
  { id: '5.0', name: '5.0' },
]

export const MasterProvider = ({ children }) => {
  const [masterHeadquarters, setMasterHeadquarters] = useState<any>([])
  const [masterDepartments, setMasterDepartments] = useState<any>([])
  const [masterEvaluationIndexs, setMasterEvaluationIndexs] = useState<any>([])
  const [masterInHouseCosts, setMasterInHouseCosts] = useState<any>([])
  const [masterPositions, setMasterPositions] = useState<any>([])
  const [masterRates] = useState<any>(_masterRates)
  const [masterProjectStatus] = useState<any>(_masterProjectStatus)
  const [masterEvaluationPoints] = useState<any>(_masterEvaluationPoints)
  const [masterEvaluationYear] = useState<any>(_masterEvaluationYear)
  const [masterEvaluationDate] = useState<any>(_masterEvaluationDate)
  const [masterEvaluationDate2022] = useState<any>(_masterEvaluationDate2022)
  const [masterEvaluationDate2023] = useState<any>(_masterEvaluationDate2023)
  const [masterEvaluationDate2024] = useState<any>(_masterEvaluationDate2024)
  const [masterFinalEvaluationIndexs] = useState<any>(_masterFinalEvaluationIndexs)
  const [masterUsers, setMasterUsers] = useState<any>([])

  const _fetchMasterUsers = async () => {
    await fetchMasterUsers().then(data => {
      // @ts-ignore
      setMasterUsers(data.data.users)
    })
  }

  const _fetchMasterHeadquarters = async () => {
    await fetchMasterHeadquarters().then(data => {
      // @ts-ignore
      setMasterHeadquarters(data.data.master)
    })
  }

  const _fetchMasterDepartments = async () => {
    await fetchMasterDepartments().then(data => {
      // @ts-ignore
      setMasterDepartments(data.data.master)
    })
  }

  const _fetchMasterEvaluationIndexs = async () => {
    await fetchMasterEvaluationIndexs().then(data => {
      const _masterEvaluationIndexs = []
      // @ts-ignore
      data.data.master.forEach((item, index) => {
        // @ts-ignore
        _masterEvaluationIndexs[index] = item
        if (item.competition) {
          const _competition = parseInt(item.competition)
          // @ts-ignore
          _masterEvaluationIndexs[index].competition = _competition.toFixed(2)
        }
      })
      
      setMasterEvaluationIndexs(_masterEvaluationIndexs)
    })
  }

  const _fetchMasterInHouseCosts = async () => {
    await fetchMasterInHouseCosts().then(data => {
      // @ts-ignore
      setMasterInHouseCosts(data.data.master)
    })
  }

  const _fetchMasterPositions = async () => {
    await fetchMasterPositions().then(data => {
      // @ts-ignore
      setMasterPositions(data.data.master)
    })
  }

  useEffect(() => {
    _fetchMasterHeadquarters()
    _fetchMasterDepartments()
    _fetchMasterPositions()
    _fetchMasterEvaluationIndexs()
    _fetchMasterInHouseCosts()
    _fetchMasterUsers()
  }, [])

  return (
    <MasterContext.Provider
      value={{
        masterHeadquarters,
        masterDepartments,
        masterEvaluationIndexs,
        masterInHouseCosts,
        masterPositions,
        masterUsers,
        masterProjectStatus,
        masterEvaluationPoints,
        masterRates,
        masterEvaluationYear,
        masterEvaluationDate,
        masterEvaluationDate2022,
        masterEvaluationDate2023,
        masterEvaluationDate2024,
        masterFinalEvaluationIndexs,
        setMasterHeadquarters,
        setMasterDepartments,
        setMasterEvaluationIndexs,
        setMasterInHouseCosts,
        setMasterPositions,
        setMasterUsers,
      }}
    >
      {children}
    </MasterContext.Provider>
  )
}

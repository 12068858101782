import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import { SideMenu } from '@/Components/Advanced/sideMenu'
import { PageTitle } from '@/Components/Advanced/pageTitle'
import { BackButton } from '@/Components/Advanced/backButton'
import { Button } from '@/Components/Advanced/button'
import { ProjectRegisterCard } from '@/Components/Pages/Project/_Register/card'
import { ManagerRegisterList } from '@/Components/Pages/Project/_Register/managerRegisterList'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// contexts
import { AuthContext } from '@/Contexts/auth'
import { MasterContext } from '@/Contexts/master'

const validationErrorInitial = {
  projectName: '',
  clientName: '',
  description: '',
  headquarterId: '',
  departmentId: '',
  evaluator: '',
  manager: '',
  evaluationIndex: '',
  inHouseCost: '',
  competition: '',
  projectStatus: '',
  evaluationDate: '',
}

export const ProjectRegister: React.FC<{}> = () => {
  const history = useHistory()
  const { auth } = useContext<any>(AuthContext)
  const { masterEvaluationIndexs, masterInHouseCosts } = useContext<any>(MasterContext)
  const [validationError, setValidationError] = useState(validationErrorInitial)
  const [projectName, setProjectName] = useState('')
  const [clientName, setClientName] = useState('')
  const [description, setDescription] = useState('')
  const [headquarterId, setHeadquarterId] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  const [userId, setUserId] = useState('')
  const [positionId, setPositionId] = useState('')
  // プロジェクトに追加しているメンバーIDの除外リスト
  const [excludeUserIds, setExcludeUserIds] = useState<string[]>([])
  // プロジェクト責任者用
  const [responsibleHeadquarterId, setResponsibleHeadquarterId] = useState('')
  const [responsibleDepartmentId, setResponsibleDepartmentId] = useState('')
  const [responsibleUserId, setResponsibleUserId] = useState('')
  const [responsiblePositionId, setResponsiblePositionId] = useState('')
  const [managerSplitActive, setManagerSplitActive] = useState(Boolean)
  const [evaluationIndex, setEvaluationIndex] = useState('')
  const [competition, setCompetition] = useState('0')
  const [competitionEdit, setCompetitionEdit] = useState('0')
  const [inHouseCost, setInHouseCost] = useState('0')
  const [isInHouseCost, setIsInHouseCost] = useState<boolean>(false)
  const [projectStatus, setProjectStatus] = useState('')
  const [evaluationDate, setEvaluationDate] = useState<string>('')
  const [beginDate, setBeginDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(
    dayjs()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
  )

  const _changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const _setManagerSplitActive = () => {
    setManagerSplitActive(!managerSplitActive)
  }

  const handleChange = e => {
    if (e.target.name === 'projectName') {
      setProjectName(e.target.value)
    }

    if (e.target.name === 'clientName') {
      setClientName(e.target.value)
    }

    if (e.target.name === 'description') {
      setDescription(e.target.value)
    }

    if (e.target.name === 'projectStatus') {
      setProjectStatus(e.target.value)
    }

    if (e.target.name === 'headquarterId') {
      setHeadquarterId(e.target.value)
    }

    if (e.target.name === 'departmentId') {
      setDepartmentId(e.target.value)
    }

    if (e.target.name === 'userId') {
      setUserId(e.target.value)
    }

    if (e.target.name === 'positionId') {
      setPositionId(e.target.value)
    }

    if (e.target.name === 'responsibleHeadquarterId') {
      setResponsibleHeadquarterId(e.target.value)
    }

    if (e.target.name === 'responsibleDepartmentId') {
      setResponsibleDepartmentId(e.target.value)
    }

    if (e.target.name === 'responsibleUserId') {
      setResponsibleUserId(e.target.value)
    }

    if (e.target.name === 'responsiblePositionId') {
      setResponsiblePositionId(e.target.value)
    }

    if (e.target.name === 'evaluationIndex') {
      setEvaluationIndex(e.target.value)
      const _projectEvaluationTarget = masterEvaluationIndexs.find(item => item.evaluation_index_id === e.target.value)
      // 自動的に倍率が変更される
      setCompetition(_projectEvaluationTarget.competition)
      // 倍率手動変更はリセット
      setCompetitionEdit('0')
      setIsInHouseCost(false)
      // 内製費ID
      if (e.target.value === '77a48080-b6da-d304-99a9-151564f97373') {
        setIsInHouseCost(true)
      }
    }

    if (e.target.name === 'competition') {
      // 倍率手動変更時
      setCompetitionEdit(e.target.value)
    }


    if (e.target.name === 'inHouseCost') {
      setInHouseCost(e.target.value)
    }
  }

  const setDate = date => {
    setEvaluationDate(dayjs(date).format('YYYY-MM-DD'))
  }

  const goToProject = () => {
    history.push({
      pathname: `/project`,
    })
  }

  const _validate = () => {
    let error = false
    const _validationError = { ...validationError }
    // プロジェクト名
    if (projectName === '') {
      _validationError.projectName = 'プロジェクト名を入力してください'
      error = true
    }
    if (projectName !== '') {
      _validationError.projectName = ''
    }

    // クライアント名
    if (clientName === '') {
      _validationError.clientName = 'クライアント名を入力してください'
      error = true
    }
    if (clientName !== '') {
      _validationError.clientName = ''
    }

    // プロジェクト概要
    if (description === '') {
      _validationError.description = 'プロジェクト概要を入力してください'
      error = true
    }
    if (description !== '') {
      _validationError.description = ''
    }

    // プロジェクト評価指標
    if (evaluationIndex === '') {
      _validationError.evaluationIndex = 'プロジェクト評価指標を入力してください'
      error = true
    }
    if (evaluationIndex !== '') {
      _validationError.evaluationIndex = ''
    }

    // プロジェクト評価指標(金額)
    if (evaluationIndex === '77a48080-b6da-d304-99a9-151564f97373') {
      if (inHouseCost === '' || inHouseCost === '0' || !inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = '金額に正しい数値を入力してください'
        error = true
      }
      if (inHouseCost !== '' && inHouseCost !== '0' && inHouseCost.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
        _validationError.inHouseCost = ''
      }
    }

    // プロジェクト評価指標(倍率)
    if (competition === '' || !competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = '倍率に正しい数値を入力してください'
      error = true
    }
    if (competition !== '' && competition.match(/^([1-9]\d*|0)(\.\d+)?$/)) {
      _validationError.competition = ''
    }

    // 評価者
    if (userId === '' || positionId === '') {
      _validationError.evaluator = '評価者の情報を正しく設定してください'
      error = true
    }
    if (userId !== '' && positionId !== '') {
      _validationError.evaluator = ''
    }

    // 責任者
    if (managerSplitActive) {
      if (responsibleUserId === '' || responsiblePositionId === '') {
        _validationError.manager = '責任者の情報を正しく設定してください'
        error = true
      }
      if (responsibleUserId !== '' && responsiblePositionId !== '') {
        _validationError.manager = ''
      }
    }

    // プロジェクトステータス
    if (projectStatus === '' || projectStatus === '選択') {
      _validationError.projectStatus = 'ステータスを設定して下さい'
      error = true
    }
    if (projectStatus !== '' && projectStatus !== '選択') {
      _validationError.projectStatus = ''
    }

    // 評価年月日
    if (evaluationDate === '') {
      _validationError.evaluationDate = '評価年月日を設定してください'
      error = true
    }
    if (evaluationDate !== '') {
      _validationError.evaluationDate = ''
    }

    if (error) {
      alert('入力内容に誤りがあります')
    }

    setValidationError(_validationError)
    return error
  }

  const goToProjectRegisterStaff = async () => {
    if (_validate()) return
    let members = [
      {
        user_id: userId,
        headquarter_id: headquarterId,
        department_id: departmentId,
        position_id: positionId,
        member_index: 0,
        begin_date: beginDate,
        end_date: endDate,
        role: 'evaluatorAndManager',
      },
    ]

    if (managerSplitActive) {
      members = [
        {
          user_id: userId,
          headquarter_id: headquarterId,
          department_id: departmentId,
          position_id: positionId,
          member_index: 0,
          begin_date: beginDate,
          end_date: endDate,
          role: 'evaluator',
        },
        {
          user_id: responsibleUserId,
          headquarter_id: responsibleHeadquarterId,
          department_id: responsibleDepartmentId,
          position_id: responsiblePositionId,
          member_index: 1,
          begin_date: beginDate,
          end_date: endDate,
          role: 'manager',
        },
      ]
    }

    history.push({
      pathname: `/project/register/staff`,
      state: {
        projectName,
        clientName,
        description,
        beginDate,
        endDate,
        evaluationIndex,
        inHouseCost,
        competition,
        competitionEdit,
        projectStatus,
        evaluationDate,
        members,
        excludeUserIds,
      },
    })
  }

  useEffect(() => {
    if (managerSplitActive) {
      setResponsibleHeadquarterId('')
      setResponsibleDepartmentId('')
      setResponsibleUserId('')
      setResponsiblePositionId('')
    }
  }, [managerSplitActive])

  useEffect(() => {
    // メンバーID除外リスト
    const _excludeUserIds:string[] = []
    _excludeUserIds.push(userId)
    if (managerSplitActive) {
      _excludeUserIds.push(responsibleUserId)
    }
    setExcludeUserIds(_excludeUserIds)
  }, [userId, responsibleUserId])

  useEffect(() => {
    // 入力した金額に合わせて倍率を変更する
    masterInHouseCosts.forEach(masterInHouseCost => {
      if (parseInt(inHouseCost) > parseInt(masterInHouseCost.begin_price)) {
        setCompetition(masterInHouseCost.competition)
      }
    })
  }, [inHouseCost])

  useEffect(() => {
    // ログイン済みか判定する
    if (auth) {
      // 3等級以下はリダイレクト
      if (auth.rate <= 3) {
        history.push({
          pathname: `/`,
        })
      }
    } else {
      history.push({
        pathname: `/`,
      })
    }
  }, [])

  return (
    <>
      <Header />
      <div css={mainWrapperStyle}>
        <SideMenu activeMenuName="project-register" />
        <div css={containerStyle}>
          <PageTitle title={'プロジェクト登録'} />
          <div css={mainContentsStyle}>
            <div css={topButtonWrapperStyle}>
              <BackButton onClick={goToProject} />
            </div>
            <div css={stepWrapperStyle}>
              <img src={require('@/static/images/project-register-step1.svg')} />
            </div>
            <ManagerRegisterList
              handleChange={handleChange}
              headquarterId={headquarterId}
              departmentId={departmentId}
              userId={userId}
              positionId={positionId}
              responsibleHeadquarterId={responsibleHeadquarterId}
              responsibleDepartmentId={responsibleDepartmentId}
              responsibleUserId={responsibleUserId}
              responsiblePositionId={responsiblePositionId}
              managerSplitActive={managerSplitActive}
              excludeUserIds={excludeUserIds}
              setManagerSplitActive={_setManagerSplitActive}
              validationError={validationError}
            />
            <ProjectRegisterCard
              handleChange={handleChange}
              project={{ projectName, clientName, description, projectStatus }}
              isInHouseCost={isInHouseCost}
              evaluationIndex={evaluationIndex}
              competition={competitionEdit !== '0' ? competitionEdit : competition}
              inHouseCost={inHouseCost}
              beginDate={beginDate}
              endDate={endDate}
              changeRangeDate={_changeRangeDate}
              setDate={date => setDate(date)}
              evaluationDate={evaluationDate}
              validationError={validationError}
            />
            <div css={saveButtonWrapperStyle}>
              <Button label={'スタッフ登録へ'} onClick={goToProjectRegisterStaff} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const containerStyle = css({
  width: '100%',
  backgroundColor: '#EDF2F9',
})

const mainContentsStyle = css({
  width: '100%',
  padding: '24px 34px',
  backgroundColor: '#EDF2F9',
})

const topButtonWrapperStyle = css({
  marginBottom: '26px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const stepWrapperStyle = css({
  marginBottom: '75px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const saveButtonWrapperStyle = css({
  margin: '0 0 80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

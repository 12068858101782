import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Header } from '@/Components/Advanced/header'
import ReactLoading from 'react-loading'

// apis
import { login } from '@/Apis/scopeProjectRecorder'

// contexts
import { AccountContext } from '@/Contexts/account'
import { AuthContext } from '@/Contexts/auth'

export const Maintenance: React.FC<{}> = () => {
  const { setAccount } = useContext<any>(AccountContext)
  const { setAuth } = useContext<any>(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const history = useHistory()

  const handleChange = e => {
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    }

    if (e.target.name === 'password') {
      setPassword(e.target.value)
    }
  }

  const _login = async () => {
    setIsLoading(true)
    await login(email, password).then(data => {
      setIsLoading(false)
      // @ts-ignore
      if (data && data.success) {
        // @ts-ignore
        setAuth(data.data)
        // @ts-ignore
        setAccount(data.data)
        goToProjects()
      } else {
        setIsLoginError(true)
      }
    })
  }

  const goToProjects = () => {
    history.push({
      pathname: `/project`,
    })
  }

  const goToPasswordForget = () => {
    history.push({
      pathname: `/password-forget`,
    })
  }

  return (
    <>
      <Header isLogin={false} />
      <div css={mainWrapperStyle}>メンテナンス中です</div>
      {isLoading && (
        <div css={loadingOverlayStyle}>
          <ReactLoading type="spin" color="#004CAD" height="100px" width="100px" className="mx-auto" />
        </div>
      )}
    </>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const borderStyle = css({
  margin: '15px 0 30px',
  borderBottom: '1px solid #D9D9D9',
  width: '100%',
})

const loginContainerStyle = css({
  margin: '0 auto',
  padding: '50px',
  width: '500px',
  borderRadius: '15px',
  backgroundColor: '#FFFFFF',
  color: '#3e3e3e',
  h2: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
})

const loginGuideStyle = css({
  fontSize: '13px',
})

const inputWrapperStyle = css({
  margin: '30px 0 0',
})

const inputLabelStyle = css({
  width: '100%',
  fontSize: '14px',
})

const inputStyle = css({
  width: '100%',
  border: '1px solid #D9D9D9',
  borderRadius: '6px',
  backgroundColor: '#F9F9F9',
  color: '#151619',
  fontSize: '16px',
  margin: '10px 0 0',
  padding: '12px 20px',
  '&::placeholder': {
    color: '#C4C4C4',
  },
})

const inputErrorStyle = css(inputStyle, {
  border: '1px solid rgb(255, 39, 0)',
})

const loginErrorTextStyle = css({
  color: 'rgb(255, 39, 0)',
  fontSize: '12px',
  fontWeight: 'bold',
})

const buttonWrapperStyle = css({
  margin: '50px 0 0',
})

const loginButton = css({
  border: 'none',
  padding: '14px 0',
  borderRadius: '24px',
  width: '100%',
  backgroundColor: '#004CAD',
  color: '#FFFFFF',
  display: 'block',
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const disabledLoginButton = css(loginButton, {
  backgroundColor: '#ccc',
  '&:hover': {
    opacity: 1,
  },
})

const passwordResetLinkStyle = css({
  margin: '20px 0 0',
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})

const loadingOverlayStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: '20%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})
